Canvas {
  /*background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(186, 223, 255, 1) 100%);*/
  z-index: 1;
}

#loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.loading-bar-container {
  width: 500px;
  height: 3px;
  background: #15414b;
}

.loading-bar {
  height: 3px;
  background: white;
}

.loading-data {
  display: block;
  position: relative;
  font-variant-numeric: tabular-nums;
  color: #f0f0f0;
  font-size: 1em;
}

#loadergif {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  object-fit: contain;
}

#ll {
  height: 100vh;
  position: fixed;
  width: 100vw
}

#blank {
  background-color: white;
  height: 100vh;
  z-index: -1;
}

#hh {
  position: fixed;
  top: 0;
  z-index: 1;
}

#progress {
  color: white
}



#media {
  display: flex;
  position: fixed;
  flex-direction: row;
  width: 130px;
  justify-content: space-between;
  top: 10px;
  right: 20px;
  z-index: 2;
}

#media a {
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

#twitter {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.55;
  background: transparent;
}

#noise {
  height: 100vh;
  width: 100vw;
  opacity: 0.09;
  position: fixed;
  pointer-events: none;
  z-index: 1;
  background-image: url("./static/noisepp3.png");
}

#audio-control {
  position: fixed;
  width: 32px;
  height: 32px;
  top: 8px;
  left: 20px;
  opacity: 0.55;
  cursor: pointer;
}

#themeChange {
  position: fixed;
  width: 10%;
  height: 32px;
  bottom: 8px;
  left: 20px;
  opacity: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0);
  opacity: 0.7;
}

#fitcontain,
#mute {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#rotateit {
  position: fixed;
  opacity: 0.8;
  width: 10vh;
  height: 10vh;
  right: 1vw;
  bottom: 10px;
  cursor: pointer;
}

form button {
  width: 400px;
  height: 100px;
  border-radius: 30px;
  border: none;
  position: absolute;
  right: 220px;
  bottom: 30%;
  font-size: 50px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(252, 254, 255, 0.8);
  cursor: pointer;
}

input {
  display: block;
  width: 50%;
  height: 10%;
  font-size: 40px;
  margin: 20px;
  background-color: white;
  border: none;
  color: black;
  border-radius: 30px;
  padding: 20px;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgb(0, 128, 128) inset;
}

textarea {
  display: block;
  width: 50%;
  height: 40%;
  font-size: 40px;
  margin: 20px;
  padding: 20px;
  border: none;
  color: black;
  border-radius: 30px;
}

h1 {
  font-size: 60px;
  margin-left: 20px;
  margin-top: 120px;
  color: rgb(255, 255, 255);
}

.content {
  height: 800px;
  width: 1800px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: transparent;
  border-radius: 80px;
}


#mainform {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
}

#formb {
  display: none;
}

#successM {
  position: absolute;
  right: 100px;
  top: 30%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 600px;
  height: 20%;
  border-radius: 20px;
  padding: 1%;
  font-size: 40px;
  font-weight: bold;
  display: none;
}